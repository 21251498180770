import React, { Component } from 'react';
import './Footer.css';

export class Footer extends Component {
  render() {
    return (
      <div>
        <footer>
          Espresso and the espresso logo are trademarks of Espresso Systems. All rights reserved.
        </footer>
      </div>
    )
  }
}

export default Footer