import React, { Component } from 'react';
import logo from '../espresso-logo-black-bg.png';
import Footer from '../Components/Footer';
import './Landing.css';

class Landing extends Component {
  render() {
    return (
        <div className="hero">
        <header className="hero-header">
          <img src={logo} className="hero-logo" alt="logo" />
          <h5>
            A better cap table and
            <br/>
            liquidation waterfall platform.
          </h5>
        </header>
        <Footer />
        </div>  
    )
  }
}

export default Landing;