import React from 'react';
import { Route, Routes } from "react-router-dom";
import Landing from "./Pages/Landing";

export default function MainRouter() {
  return (
    <div>
        <Routes>
            <Route exact path="/" element={<Landing />} />
        </Routes>
    </div>
  )
}
